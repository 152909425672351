import React, {useCallback, useMemo, useRef} from 'react'
import { isMobile } from 'react-device-detect'
import withRedux from '../../mapper'
import Eye from '../../../UI/Eye'
import ConnectedSpriteSvgComponent from '../../../UI/ConnectedSpriteSvgComponent'
import Sort from '../../../UI/Sort'
import SideBarClock from '../../../../assets/imgs/sidebar_clock'
import {useTranslation} from "react-i18next";

const CategoryTab = ({
  item,
  lampData,
  selectedCategoryId,
  globalLoading,
  index,
  openCategory,
  setMapRequirement,
  setHints,
  setQuizPopup,
  calculateHeight,
  categoryModalRefs,
  browserInfo,
  toggleItemsFromMap,
  toggleItemsFromTimeLine,
  toggleSorting,
  sortBoard,
  filterAction,
  subItemsRend,
  stopPinAnimation,
  getArticleFromAPI,
  isLesson,
}) => {
  const thisTabButton = useRef(null)
    const {t} = useTranslation()

  const mainSubItemStyles = useMemo(() => {
      if(!thisTabButton.current) return;
      return {
          height: 'fit-content',
          minHeight: '100px',
          maxHeight: window.innerWidth >= 900 && window.innerWidth <= 1800
              ? `${item.height || calculateHeight(thisTabButton.current)}px`
              : `calc(100vh - 293px -  ${index * 65}px)`
      }
  }, [calculateHeight, index, item.height, thisTabButton.current]);

  const mainSubItemsWrapperStyles = useMemo(() => {
      if(!thisTabButton.current) return;
      return {
          flexDirection: 'column',
          height: 'fit-content',
          maxHeight: window.innerWidth >= 900 && window.innerWidth <= 1800
              ? `${(item.height || calculateHeight(thisTabButton.current)) - 70 > 0
                  ? (item.height || calculateHeight(thisTabButton.current)) - 70
                  : 0}px`
              : browserInfo.isSafari && window.innerWidth > 1800 ?
                  `calc(100dvh - 657px -  ${index * 65}px)` :
                  `calc(100dvh - 357px -  ${index * 65}px)`
      }
  }, [thisTabButton.current, browserInfo.isSafari, calculateHeight, index, item.height])

  const handleCategoryClick = useCallback(
    (e) => {
      openCategory(item?.id)
      setMapRequirement(false)
      setHints(false)
      setQuizPopup(false)
      item.height = calculateHeight(e.target)
    },
    [
      item,
      openCategory,
      setMapRequirement,
      setHints,
      setQuizPopup,
      calculateHeight,
    ],
  )

  const renderSubItemList = useCallback(
    () =>
      subItemsRend(item).map((articleItem, artIndex) => {
        const isLampItem = lampData.some((lamp) => lamp.id === articleItem.id)
        if (isLampItem) return null

        return (
          <li
            key={`acat_${item.id}_${articleItem.subIndex}_${articleItem.subItem.id}_${artIndex}_${articleItem.id}`}
            className="main__subitem-li">
            <div
              style={{
                '--data-color': item.color,
                opacity: !articleItem.show ? 0.5 : 1,
              }}>
              <p className="with-svg">
                <ConnectedSpriteSvgComponent
                  name={articleItem?.subItem?.spriteIcon}
                />
              </p>
              <div
                className="main__subitem-libtn"
                onClick={() => {
                  stopPinAnimation()
                  getArticleFromAPI(articleItem.id)
                }}>
                {articleItem.title}
              </div>
            </div>
            <label
              id={`category_filters_icon_${articleItem.id}`}
              className="main__subitem-label category-filters category_filters_icon"
              data-show={!articleItem.show}>
              <input
                className="main__subitem-checkbox show-checkbox"
                type="checkbox"
                onChange={() =>
                  toggleItemsFromMap(
                    item.id,
                    articleItem.subItem.id,
                    'article',
                    articleItem.id,
                    articleItem.elementsIds,
                  )
                }
                checked={articleItem.show}
              />
              <Eye show={articleItem.show} />
            </label>
            {!isMobile ||
            (window.innerWidth > window.innerHeight && isMobile) ? (
              <label className="main__subitem-label clock-icon">
                <input
                  className="main__subitem-checkbox show-checkbox"
                  type="checkbox"
                  onChange={() =>
                    isLesson
                      ? toggleItemsFromTimeLine(
                          item.id,
                          articleItem.subItem.id,
                          'article',
                          articleItem.id,
                          articleItem.elementsIds,
                          'showInTimeLine',
                        )
                      : null
                  }
                  checked={articleItem.showInTimeLine}
                />
                <SideBarClock
                  show={articleItem.showInTimeLine}
                  isTopic={!isLesson}
                />
              </label>
            ) : null}
          </li>
        )
      }),
    [subItemsRend, item, lampData, isLesson, stopPinAnimation, getArticleFromAPI, toggleItemsFromMap, toggleItemsFromTimeLine],
  )

  return (
    <li
      key={item.id + 'category'}
      className={`main__tab persons ${selectedCategoryId === item.id && 'active'}`}
      data-is-loading={globalLoading}>
      <button
        className="main__tab-btn"
        type="button"
        ref={el => thisTabButton.current = el}
        style={{
          backgroundColor:
            selectedCategoryId === item?.id ? item?.color : 'transparent',
        }}
        onClick={handleCategoryClick}>
        <span className="main__tab-icon icon-wrapper">
          <ConnectedSpriteSvgComponent name={item?.spriteIcon} />
        </span>
      </button>

      {item?.subCategories?.length > 0 && (
        <div
          ref={categoryModalRefs[index]}
          className="main__subitem"
          key={`a-${item.id}`}
          style={mainSubItemStyles}>
          <div className="main__subitem-heading" data-color={item.color}>
            <span
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                '--data-color': item.color,
              }}
            />
            <h2 className="main__subitem-title">{item.title}</h2>
            <label className="main__subitem-label" key={`z-${item.id}`}>
              <input
                className="main__subitem-checkbox show-checkbox"
                type="checkbox"
                onChange={() => toggleItemsFromMap(item.id, null, 'category')}
                checked={item.show}
              />
              <Eye show={item.show} />
            </label>
            {!isMobile ||
            (window.innerWidth > window.innerHeight && isMobile) ? (
              <label className="main__subitem-label clock-icon" key={item.id}>
                <input
                  className="main__subitem-checkbox show-checkbox"
                  type="checkbox"
                  onChange={() =>
                    isLesson
                      ? toggleItemsFromTimeLine(
                          item.id,
                          null,
                          'category',
                          null,
                          null,
                          'showInTimeLine',
                        )
                      : null
                  }
                  checked={item.showInTimeLine}
                />
                <SideBarClock show={item.showInTimeLine} isTopic={!isLesson} />
              </label>
            ) : null}

            <button
              className="main__subitem-sort"
              type="button"
              onClick={() => toggleSorting(!sortBoard)}>
              <span>
                <Sort />
              </span>
            </button>

            <div
              className="sort-board category_sorted_tooltip"
              id="category_sorted_tooltip"
              data-active={sortBoard}>
              <span
                className="sort-title"
                style={{ textTransform: 'uppercase' }}>
                {t('sortBy.title')}
              </span>
              <div onClick={() => toggleSorting(false, 'chronologically')}>
                <input
                  name={`sort${item.id}`}
                  type="radio"
                  id={`chronologically${item.id}`}
                />
                <label htmlFor={`chronologically${item.id}`}>
                    {t('sortBy.timeRange')}
                </label>
              </div>
              <div onClick={() => toggleSorting(false, 'alphabetically')}>
                <input
                  checked={filterAction === 'alphabetically'}
                  name={`sort${item.id}`}
                  type="radio"
                  id={`alphabetically${item.id}`}
                />
                <label htmlFor={`alphabetically${item.id}`}>
                    {t('sortBy.alphabetically')}
                </label>
              </div>
            </div>
          </div>

          <div className="main__subitem-itemswrapper" style={mainSubItemsWrapperStyles}>
            <ul className="main__subitem-list person">{renderSubItemList()}</ul>
          </div>
        </div>
      )}
    </li>
  )
}

export default withRedux(CategoryTab)
