import ReactDOM from 'react-dom';
import MapElementsPopupRadius from "../../../components/UI/MapElementsPopupRadius";
import MapElementsPopup from "../../../components/UI/MapElementsPopup";
import {handleGetPopupAnchor, handleGetPopupOffset, handleGetPopupWidth} from "./utils";
import mapboxgl from "mapbox-gl";

// Create popup with circular radius
export const createPopupWithRadius = (mapRef,coordinates, child, activeStyle, selectElements) => {
    const div = document.createElement("div");
    ReactDOM.render(
        <MapElementsPopupRadius
            activeStyle={activeStyle}
            child={child}
            coordinates={coordinates}
            selectELements={selectElements}
        />,
        div
    );

    return new mapboxgl.Popup({
        closeOnClick: false,
        closeOnMove: true,
        focusAfterOpen: true,
        anchor: 'center',
        maxWidth: '30vw',
        className: 'elements-popup-radius',
    })
        .setLngLat(coordinates)
        .setDOMContent(div)
        .addTo(mapRef.current);
};

// Create standard popup for larger datasets
export const createPopup = (mapRef,coordinates, child, selectElements) => {
    const div = document.createElement("div");
    ReactDOM.render(
        <MapElementsPopup
            child={child}
            coordinates={coordinates}
            selectELements={selectElements}
        />,
        div
    );

    return new mapboxgl.Popup({
        closeOnClick: false,
        closeOnMove: true,
        focusAfterOpen: true,
        anchor: handleGetPopupAnchor(),
        offset: handleGetPopupOffset(),
        maxWidth: handleGetPopupWidth(),
        className: 'elements-popup-modal',
    })
        .setLngLat(coordinates)
        .setDOMContent(div)
        .addTo(mapRef.current);
};
