import axios from 'axios'
import {formatArticleTime, formatLessonTime, formatServerResponseTime} from '../../map/utils'
import {setCompassNewAngle, setCompassRotate} from "../../../store/actions/compassStateAction";
import {loadingArticles, setArticles, setSelectedArticle} from "../../../store/actions/articles";
import {getArticleAPI} from "../../../service/article";
import {getTopicNavigationAPI} from "../../../service/topics";
import {dispatchTopicNavigation} from "../../../store/actions/topics";
import i18n from "../../../locales/i18n";

// export const processTimeLineLessonItemsRendering = async (
//     dataID,
//     lessonId,
//     data,
//     fromTopic,
//     timeLineSelectedDataGroup,
//     categories,
//     setTopicId
// ) => {
//     const timeLineSelectedDataItem = []
//     let lessonID = lessonId
//     let nextLesson = null
//     let prevLesson = null
//     if (fromTopic) {
//         lessonID = dataID
//         nextLesson = null
//     }
//
//     await axios
//         .get(
//             `${process.env.REACT_APP_GIS_URL}/Metadata/Lessons/${lessonID}`
//         )
//         .then(async (response) => {
//             if (response.status === 200) {
//                 let lessonData = response.data
//                 await axios
//                     .get(
//                         `${process.env.REACT_APP_GIS_URL}/Metadata/Topics/${lessonData.topicIds[0]}`
//                     )
//                     .then((res) => {
//                         if (res.status === 200) {
//                         !!setTopicId && setTopicId(res?.data?.id)
//                             let topicData = res.data
//                             const { startFrom, endTo } =  formatServerResponseTime(lessonData)
//                             timeLineSelectedDataItem.push({
//                                 id: 0,
//                                 lessonID: lessonData.id,
//                                 content: `Դաս: ${lessonData?.language[0].title} - Թեմա։ ${topicData?.language[0].title}`,
//                                 titles:{
//                                   lessonTitle:lessonData?.language[0].title,
//                                   topicTitle:topicData?.language[0].title,
//                                 },
//                                 nextLessonID: nextLesson,
//                                 prevLessonID: prevLesson,
//                                 startFrom: startFrom,
//                                 endTo: endTo,
//                                 start: startFrom,
//                                 end: endTo,
//                                 className: 'isGroup',
//                                 group: 0,
//                                 color: lessonData.color,
//                             })
//
//                         }
//                     })
//             }
//         })
//     data.sort(function (a, b) {
//         return a.order - b.order
//     })
//     data.forEach((item) => {
//         const { lessonStartFrom, lessonEndTo } = formatLessonTime(item)
//         let cat = categories.find((x) => x.id === item.categoryId)
//         timeLineSelectedDataItem.push({
//             id: item.id,
//             articleId: item.id,
//             catId: item.categoryId,
//             catIcon: cat?.iconPath,
//             content:
//                 item.content[0].shortTitle !== null &&
//                 item.content[0].shortTitle.length > 0
//                     ? item.content[0].shortTitle
//                     : item.content[0].mainTitle,
//             start: lessonStartFrom,
//             end: lessonEndTo,
//             group: item?.isLamp
//                 ? 111
//                 : timeLineSelectedDataGroup?.find(
//                     (x) => x.catId === item.categoryId
//                 )?.id,
//             className: 'isInner',
//             color: cat?.color,
//             show: true,
//             isLamp: item.isLamp,
//         })
//     })
//     return timeLineSelectedDataItem
// }
export const processTimeLineLessonItemsRendering =  async (   dataID,
                                                              lessonId,
                                                              data,
                                                              fromTopic,
                                                              timeLineSelectedDataGroup,
                                                              categories,
                                                              setTopicId,
                                                              getState,
                                                              dispatch,
                                                              dispatchTopicNavigation,
                                                              lng
) => {
    function convertToFourDigitYear(year,manth,day) {
        var isNegative = year < 0;
        var absoluteYear = Math.abs(year);
        var paddedYear = absoluteYear.toString().padStart(4, '0');
        var date = new Date(`0001-${manth ? manth : '01'}-${day ? day : '01'}`);
        date.setFullYear(paddedYear);
        if (isNegative) {
            // Convert negative year to BCE
            date.setFullYear(-paddedYear);
        }
        return date;
    }
    function getArticleApi (id,getState,dispatch,stopFly) {
        dispatch(loadingArticles());
        getArticleAPI(id).then((article) => {
            let articles = getState().articles.data;
            const openedArticles = articles.filter((x) => x.show);
            if (
                articles.find((x) => x.id == id) !== undefined ||
                typeof articles.find((x) => x.id == id) !== "undefined"
            ) {
                const indexArticle = articles.findIndex((x) => x.id === article.id);
                articles[indexArticle].show = true;
            } else {
                if (openedArticles.length > 0) {
                    openedArticles.forEach((article) => {
                        const indexArticle = articles.findIndex((x) => x.id === article.id);
                        articles.splice(indexArticle, 1);
                    });
                }
                articles.push(article);
            }

            dispatch(
                setSelectedArticle({
                    id: id,
                    type: article.articleData.type,
                    fly: !!stopFly
                })
            );
            // dispatch(setSearch(false))
            dispatch(setArticles(articles));
        });
    }

    function calcByMonth(fullYear,fullMonth){
        let yearStart = fullYear
        let monthStart = fullMonth - 3

        let yearEnd = fullYear
        let monthEnd = fullMonth + 3

        if(monthStart <= 0){
            monthStart = 12 + monthStart
            yearStart -= 1
        }
        if(monthEnd > 12){
            monthEnd = 0 + (monthEnd - 12)
            yearEnd += 1
        }
        return {
            start:[yearStart, monthStart, 15],
            end:[yearEnd, monthEnd, 15]
        }

    }

    const timeLineDataItem = new Array()
    timeLineDataItem.__proto__.getItemByDateRange = function (step,time) {
        const fullYear = time.getFullYear()
        const fullMonth = time.getMonth()

        const rangeStart = {
            1000:[fullYear+250, 0, 0],
            500:[fullYear+150, 0, 0],
            100:[fullYear+5, 0, 0],
            50:[fullYear+2, 0, 0],
            10:[fullYear+1, 0, 0],
            5:[fullYear-1, '06', 0],
            1:calcByMonth(fullYear,fullMonth).start,
        }
        const rangeEnd = {
            1000:[fullYear-250, 0, 0],
            500:[fullYear-150, 0, 0],
            100:[fullYear-5, 0, 0],
            50:[fullYear-2, 0, 0],
            10:[fullYear-1, 0, 0],
            5:[fullYear+1, '06', 0],
            1:calcByMonth(fullYear,fullMonth).end
        }
        const start =  convertToFourDigitYear(...rangeStart[step]);
        const end =  convertToFourDigitYear(...rangeEnd[step]);
        return this.filter((item)=>{
            return (
                item.className === "related" &&
                !item.inGroup &&
                (new Date(item.start) >= start && new Date(item.start) <= end ||
                    new Date(item.end) >= start && new Date(item.end) <= end ||
                    new Date(item.start) <= start && new Date(item.end) >= end)
            );
        })
    }

    let lessonID = lessonId
    let nextLesson = null
    let prevLesson = null
    if (fromTopic) {
        lessonID = dataID
        nextLesson = null
    }
     await getTopicNavigationAPI(data?.topicIds[0], lng)
            .then((res) => {
                    !!setTopicId && setTopicId(res?.current?.id)
                    let topicData = res?.current
                    dispatchTopicNavigation(res)
                    const { startFrom, endTo} =  formatServerResponseTime(data)
                    const content = data?.language.find(x => x.languageId === lng)
                    timeLineDataItem.push({
                        id: data.id,
                        order: 1,
                        lessonID: data.id,
                        content: `Դաս: ${content.title} - Թեմա։ ${topicData?.title}`,
                        titles:{
                          lessonTitle:content.title,
                          topicTitle:topicData?.title,
                        },
                        nextLessonID: nextLesson,
                        prevLessonID: prevLesson,
                        startFrom: startFrom,
                        endTo: endTo,
                        start: startFrom,
                        end: endTo,
                        className: 'isGroup',
                        color: '#2c476c',
                    })
            })
    await axios
        .get(
            `${process.env.REACT_APP_GIS_URL}/Metadata/TimelineMarkers?lessonId=${data.id}&languageId=${lng}`
        )
        .then((res) => {
            if (res.status === 200) {
                res?.data?.map((article,index)=>{
                    article.items.map((el,idx)=> {
                        timeLineDataItem.push({
                            id: `${data.id}-${index}-${idx}`,
                            order: index * idx + 2,
                            start: convertToFourDigitYear((article.isBc ? '-' : '') + article.year),
                            end: convertToFourDigitYear((article.isBc ? '-' : '') + article.year),
                            color: '#2c476c',
                            groupId: data.id,
                            className: 'related',
                            elementType: el.type,
                            articleId:el?.articleId,
                            openMiddleTimeline: article.items.length > 1,
                            bounds: article?.bounds,
                            getArticle: (id) => {
                                getArticleApi(id, getState, dispatch)
                                dispatch(setCompassNewAngle(0));
                                dispatch(setCompassRotate(0));
                            }
                        })
                    })
                })
            }
        })
    return timeLineDataItem
}
