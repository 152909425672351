import React from 'react'
import withRedux from '../../mapper'

import Expend from '../../../UI/Expend'

import LampIconMenu from '../../../../assets/imgs/lamp_icon_menu.png'

const MainButton = ({ menuExpend, globalLoading, lampData, setMenuExpend }) => {
  return (
    <div
      className={`categories-collapse main__tab ${menuExpend ? 'active' : ''}`}
      data-is-loading={globalLoading}
      style={{ '--loader-color': '#ececec ' }}>
      <button
        className="main__tab-btn"
        type="button"
        onClick={() => setMenuExpend(!menuExpend)}>
        <Expend />
        {lampData && lampData?.length > 0 && !menuExpend ? (
          <div
            className={'lampIcon'}
            style={{
              position: 'absolute',
              top: -10,
              right: -10,
              background: '#FFB207',
              borderRadius: 40,
              padding: '3px 9px',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img src={LampIconMenu} />
          </div>
        ) : null}
      </button>
    </div>
  )
}
export default withRedux(MainButton)
