import React, { useEffect, useRef, useState } from 'react'
import withRedux from '../../mapper'

import QuizItem from './QuizItem'

import QuizActive from '../../../../assets/imgs/quiz_icon_article.svg'
import QuizPassive from '../../../../assets/imgs/quiz-passive.svg'
import {useTranslation} from "react-i18next";

const calculateContentHeight = (quizModalRef) => {
  return window.innerWidth >= 320 && window.innerWidth <= 1800
    ? `calc(100vh - 272px)`
    : window.innerHeight - quizModalRef?.current?.getBoundingClientRect().y - 14
}

const calculateScrollZoneHeight = (quizModalRef) => {
  return window.innerWidth >= 320 && window.innerWidth <= 1800
    ? `calc(100vh - 600px)`
    : window.innerHeight -
        quizModalRef?.current?.getBoundingClientRect().y -
        114
}

const QuizModal = ({
  quizPopup,
  globalLoading,
  setHints,
  quizData,
  setQuizPopup,
  setSelectedCategoryId,
  setOpenCategoryID,
  timelineExtend,
  setFilterAction,
  setSortedTooltipSelectItem,
  getSelectedQuizDataFromApi,
}) => {
  const quizModalRef = useRef()
  const quizScrollZone = useRef()
  const {t} = useTranslation()
  const [quizDataState, setQuizDataState] = useState([])

  useEffect(() => {
    if (quizData.length > 0) {
      setQuizDataState(quizData)
    }
  }, [quizData])

  const handleQuizButtonClick = () => {
    if (quizData.length > 0) {
      setHints(false)
      setQuizPopup(!quizPopup)
      setSelectedCategoryId(null)
      setOpenCategoryID(null)

      if (timelineExtend) {
        setFilterAction('alphabetically')
        setSortedTooltipSelectItem(1)
      }
    }
  }

  return (
    <div className={`left-side__hints hints ${quizPopup ? 'active' : ''}`}>
      <button
        className="hints__btn main__tab hints__btn_quiz"
        style={{ backgroundColor: quizPopup ? '#9E5D82' : '#fff' }}
        type="button"
        onClick={handleQuizButtonClick}
        data-is-loading={globalLoading}>
        <img src={quizPopup ? QuizActive : QuizPassive} alt="quiz" />
      </button>
      <div
        ref={quizModalRef}
        className="hints__content quiz__content"
        style={{
          height: 'fit-content',
          maxHeight: calculateContentHeight(quizModalRef),
        }}>
        <div className="hints__content_item quiz_content">
          <div className="hints__content_title">{t('quiz')}</div>
        </div>
        <div className="hints__content_itemswrapper hints__content_itemswrapper_quiz">
          <ul
            className="main__subitem-items custom-scroll-v3 main__subitem-items_quiz"
            ref={quizScrollZone}
            id="quizScrollZone"
            style={{
              height: 'fit-content',
              maxHeight: calculateScrollZoneHeight(quizModalRef),
            }}>
            {quizDataState.length > 0 &&
              quizDataState.map((item) => (
                <QuizItem
                  key={item.id + 'quiz'}
                  data={item}
                  getSelectedQuizDataFromApi={getSelectedQuizDataFromApi}
                />
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default withRedux(QuizModal)
