// Create a feature for cluster elements
export const createClusterElement = (el, selectorsRef, legends, cat) => {
    const article = selectorsRef.current.filteredCategories.getById(el?.articleIds[0]);
    const mainSub = article?.subcategory?.find((sub) => sub.isMain);
    const mainCatId = mainSub?.categoryId || article?.categoryId;
    const mainSubId = mainSub?.subCategoryId;

    return {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [el.location.longitude, el.location.latitude],
        },
        properties: {
            id: el.id,
            relationType: el.lessonRelationType,
            articleIds: el.articleIds,
            name: el.displayName || 'no name',
            yearStart: +`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
            yearEnd: +`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear()}`,
            startTime: 0,
            endTime: 0,
            visible: 'visible',
            relationFilter: 'visible',
            iconPath: 'https://cdn-icons-png.flaticon.com/512/3201/3201299.png',
            key: legends?.getKeyById(el?.layerId)?.key,
            subId: mainSubId,
            catId: mainCatId,
            color: cat[mainCatId]?.color,
            catColor: cat[mainCatId]?.color,
            spriteIcon: legends?.getKeyById(el?.layerId)?.icon,
            element: true,
        },
    };
};

// Create a feature for articles
export const createArticleFeature = (el, subCat, cat, idx) => {
    const mainSub = el?.subcategory.find((sub) => sub.isMain);
    const mainCatId = mainSub?.categoryId || el?.categoryId;
    const mainSubId = mainSub?.subCategoryId;

    return {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [el.location.longitude, el.location.latitude],
        },
        properties: {
            visible: 'visible',
            relationFilter: 'visible',
            id: el.id,
            relationType: el.lessonRelationType,
            articleIds: [el.id],
            name: el.content[0]?.shortTitle || el.content[0]?.mainTitle || 'no name',
            yearStart: +`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
            yearEnd: +`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear()}`,
            startTime: 0,
            endTime: 0,
            catId: cat[mainCatId]?.id,
            catColor: cat[mainCatId]?.color,
            subId: subCat[mainSubId]?.id,
            iconPath: subCat[mainSubId]?.iconPath,
            spriteIcon: subCat[mainSubId]?.spriteIcon,
            color: subCat[mainSubId]?.color || '#fff',
            key: subCat[mainSubId]?.key,
            isArticle: true,
            active: idx === 0,
        },
    };
};
