import { useEffect, useState } from 'react'

const useOrientationChange = () => {
  const [isLandscape, setIsLandscape] = useState(
    Math.abs(window.orientation) === 90,
  )

  useEffect(() => {
    const handleOrientationChange = () => {
      if (Math.abs(window.orientation) === 90) {
        setIsLandscape(true)
      } else {
        setIsLandscape(false)
      }
    }

    window.addEventListener('orientationchange', handleOrientationChange)

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])

  return isLandscape
}

export default useOrientationChange
