import React, { useEffect, useMemo, useState } from 'react'
import { connect, Provider } from 'react-redux'
import { store } from '../../../store'
import { getSpriteBase64Data, getSpriteData } from '../../../store/selectors'
import './style.css'

// Component to display a sprite from a Base64-encoded sprite sheet
const SpriteImageSVG = ({
  spriteJson,
  name,
  centered = false,
  getSpriteBase64Data,
}) => {
  const [spriteSize, setSpriteSize] = useState({ width: 0, height: 0 })
  const spriteProps = useMemo(() => {
    return spriteJson[name] || spriteJson['default'] || {}
  }, [spriteJson, name])

  const { width = 0, height = 0, x = 0, y = 0 } = spriteProps

  useEffect(() => {
    const img = new Image()

    // Set up image onload event
    img.onload = () => {
      setSpriteSize({
        width: img.naturalWidth,
        height: img.naturalHeight,
      })
    }

    img.onerror = (error) => {
      console.error('Error loading sprite image:', error)
    }

    // Set the src to the Base64 string
    img.src = getSpriteBase64Data

    // Clean up function
    return () => {
      // If necessary, you could reset the size state here
      setSpriteSize({ width: 0, height: 0 })
    }
  }, [getSpriteBase64Data])

  return (
    <div className={` sprite-svg ${centered ? 'centered' : ''}`}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`} // Set the viewBox according to the width and height
        xmlns="http://www.w3.org/2000/svg"
        className="sprite-svg">
        <defs>
          <clipPath id="clip">
            <rect x="0" y="0" width={width} height={height} />
          </clipPath>
        </defs>
        {/* Ensure x and y values are positioned correctly */}
        <image
          href={getSpriteBase64Data} // Use Base64 string as the image source
          x={-x} // Check offsets and ensure they are valid
          y={-y} // Check offsets
          width={spriteSize.width} // Ensure full width of the sprite is covered
          height={spriteSize.height} // Ensure full height of the sprite is covered
          clipPath="url(#clip)" // Apply clipping
          preserveAspectRatio="xMidYMin meet" // Allow stretching
        />
      </svg>
    </div>
  )
}

const mapStateToProps = (state) => ({
  spriteJson: getSpriteData(state),
  getSpriteBase64Data: getSpriteBase64Data(state), // Ensure this returns a Promise for the Base64 data
})

const ConnectedSpriteImageSVG = connect(mapStateToProps)(SpriteImageSVG)

const ConnectedSpriteSvgComponent = (props) => {
  return (
    <Provider store={store}>
      <ConnectedSpriteImageSVG {...props} />
    </Provider>
  )
}

export default ConnectedSpriteSvgComponent
