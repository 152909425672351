import {formatArticleTime, formatLessonTime, formatServerResponseTime} from '../../map/utils'
import {getArticleFromAPI, loadingArticles, setArticles, setSelectedArticle} from "../../../store/actions/articles";
import {getArticleAPI} from "../../../service/article";
import {setCompassNewAngle, setCompassRotate} from "../../../store/actions/compassStateAction";
import i18n from "i18next";

export const processMiddleTimeLineLessonItemsRendering = (data,categories,selectedLanguage,getState,dispatch) => {

    function convertToFourDigitYear(year) {
        var isNegative = year < 0;
        var absoluteYear = Math.abs(year);
        var paddedYear = absoluteYear.toString().padStart(4, '0');

        var date = new Date('0001-01-01');
        date.setFullYear(paddedYear);
        if (isNegative) {
            // Convert negative year to BCE
            date.setFullYear(-paddedYear);
        }

        return date;
    }
    function getArticleApi (id,getState,dispatch,stopFly) {
        dispatch(loadingArticles());
        const lng = getState().mapState.screenshotLanguage || (i18n.language === 'hy' ? 1 : 2)

        getArticleAPI(id,lng).then((article) => {
            let articles = getState().articles.data;
            const openedArticles = articles.filter((x) => x.show);
            if (
                articles.find((x) => x.id == id) !== undefined ||
                typeof articles.find((x) => x.id == id) !== "undefined"
            ) {
                const indexArticle = articles.findIndex((x) => x.id === article.id);
                articles[indexArticle].show = true;
            } else {
                if (openedArticles.length > 0) {
                    openedArticles.forEach((article) => {
                        const indexArticle = articles.findIndex((x) => x.id === article.id);
                        articles.splice(indexArticle, 1);
                    });
                }
                articles.push(article);
            }
            dispatch(
                setSelectedArticle({
                    id: id,
                    type: article.articleData.type,
                    fly: !!stopFly
                })
            );
            // dispatch(setSearch(false))
            dispatch(setArticles(articles));
        });
    }
    let middleTimeLineDataItem = new Array()
    middleTimeLineDataItem.__proto__.getItemByDateRange = function (step,time) {
        const fullYear = time.getFullYear()

        const rangeStart = {
                1000:[fullYear+250, 0, 0],
                500:[fullYear+150, 0, 0],
                100:[fullYear+5, 0, 0],
                50:[fullYear+2, 0, 0],
                10:[fullYear+1, 0, 0],
                5:[fullYear-1, 6, 0],
                1:[fullYear, 0, 0],
            }
        const rangeEnd = {
            1000:[fullYear-250, 0, 0],
            500:[fullYear-150, 0, 0],
            100:[fullYear-5, 0, 0],
            50:[fullYear-2, 0, 0],
            10:[fullYear-1, 0, 0],
            5:[fullYear+1, 6, 0],
            1:[fullYear, 0, 0],
        }
        const start =  convertToFourDigitYear(...rangeStart[step]);
        const end =  convertToFourDigitYear(...rangeEnd[step]);
        return this.filter((item)=>{
            return (
                item.className === "related" &&
                (new Date(item.start) >= start && new Date(item.start) <= end ||
                new Date(item.end) >= start && new Date(item.end) <= end ||
                new Date(item.start) <= start && new Date(item.end) >= end)
            );
        })
    }
    const { startFrom, endTo } = formatServerResponseTime(data)
    const content = data.language.find(x => x.languageId === selectedLanguage)

    middleTimeLineDataItem.push({
        id: data.id,
        order: 1,
        content: content.title,
        start: startFrom,
        end: endTo,
        className: 'isGroup',
        startFrom: new Date(startFrom.setFullYear(startFrom.getFullYear() + 1)),
        endTo: new Date(endTo.setFullYear(endTo.getFullYear() + 1)),
    })
    data?.articles?.map((article,index)=>{
        let cat = categories.find((x) => x.id === article?.subcategory[0]?.categoryId)
        const { articleStartFrom, articleEndTo } = formatArticleTime(article)
        if(articleStartFrom && new Date(startFrom).getFullYear() <= new Date(articleStartFrom).getFullYear()) {
            middleTimeLineDataItem.push({
                id: `${data.id}-${article.id}`,
                order: index,
                // content: article.content[0].mainTitle,
                // prevLessonID: index === 0 ? null : data.lessons[index - 1].id,
                articleId: article.id,
                category: cat,
                start: articleStartFrom || startFrom,
                end: articleStartFrom || startFrom,
                groupId: `${data.id}-${article.id}`,
                group: cat?.id,
                className: 'related-all',
                getArticle: (id) => {
                    getArticleApi(id, getState, dispatch)
                    dispatch(setCompassNewAngle(0));
                    dispatch(setCompassRotate(0));
                }
            })
        }
    })
    return middleTimeLineDataItem
}
