import { formatLessonTime, formatServerResponseTime } from '../../map/utils'

export const processMiddleTimeLineTopicItemsRendering =  (data,selectedLanguage) => {

    const timeLineDataItem = []

    const { startFrom, endTo } = formatServerResponseTime(data)
    const content = data.language.find(x => x.languageId === selectedLanguage)
    timeLineDataItem.push({
        id: data.id,
        order: 1,
        content: content.title,
        start: startFrom,
        end: endTo,
        className: 'isGroup',
        color: '#2c476c',
        startFrom: startFrom,
        endTo: endTo,
    })
    data.lessons.forEach((lesson, index) => {
        const { lessonStartFrom, lessonEndTo } = formatLessonTime(lesson)
        const content = lesson.language.find(x => x.languageId === selectedLanguage)
        timeLineDataItem.push({
            id: `${data.id}-${lesson.id}`,
            order: index + 2,
            content: content?.title,
            lessonId:lesson.id,
            nextLessonID:
                index + 1 === data.lessons.length
                    ? null
                    : data.lessons[index + 1].id,
            prevLessonID: index === 0 ? null : data.lessons[index - 1].id,
            start: lessonStartFrom,
            end: lessonEndTo,
            color: '#2c476c',
            groupId: data.id,
        })
    })
    return timeLineDataItem
}
