export const filterCategory = (action, data) => {
  let res = []
  if (action === 'chronologically') {
    res = data.sort((a, b) => a.start - b.start)
  } else if (action === 'alphabetically') {
    res = data.sort((a, b) => (a.title > b.title ? 1 : -1))
  } else if (action === 'lessonOrder') {
    res = data
  }
  return res
}

export const filterLamp = (action, data) => {
  let res = []
  if (action === 'chronologically') {
    res = data.sort((a, b) => a?.timeStart.year - b?.timeStart.year)
  } else if (action === 'alphabetically') {
    res = data.sort((a, b) =>
      a?.content[0].mainTitle > b?.content[0].mainTitle ? 1 : -1,
    )
  } else if (action === 'lessonOrder') {
    res = data.sort((a, b) => a.id - b.id)
  }
  return res
}
