import { useEffect } from 'react'

const useSplideArrows = (
  lampModalState,
  lampModalData,
  getLampModalStateIndex,
) => {
  useEffect(() => {
    const arrows = document.getElementsByClassName('splide__arrows')[0]
    if (!arrows) return

    if (lampModalState) {
      if (lampModalData?.length > 1) {
        arrows.style.display = 'block'
        addSliderCounter(arrows)
      } else {
        arrows.style.display = 'none'
      }
    }
  }, [getLampModalStateIndex, lampModalData, lampModalState])

  useEffect(() => {
    const prev = document.getElementsByClassName('splide__arrow--prev')[0]
    const next = document.getElementsByClassName('splide__arrow--next')[0]

    if (prev || next) return

    if (lampModalState) {
      prev?.addEventListener('click', onPrevHandle)
      next?.addEventListener('click', onNextHandle)
    }

    return () => {
      prev?.removeEventListener('click', onPrevHandle)
      next?.removeEventListener('click', onNextHandle)
    }
  }, [lampModalState])

  const addSliderCounter = (arrows) => {
    !!document.getElementsByClassName('counter_element')[0] &&
      document.getElementsByClassName('counter_element')[0].remove()

    const counterElement = document.createElement('div')
    counterElement.classList.add('counter_element')

    const elInnerFirst = document.createElement('span')
    elInnerFirst.classList.add('first_counter')
    elInnerFirst.innerText = `${getLampModalStateIndex + 1} `

    const elInnerSlash = document.createElement('span')
    elInnerSlash.classList.add('slash_counter')
    elInnerSlash.innerText = '/'

    const elInnerSecond = document.createElement('span')
    elInnerSecond.classList.add('first_counter')
    elInnerSecond.innerText = ` ${lampModalData.length}`

    counterElement.appendChild(elInnerFirst)
    counterElement.appendChild(elInnerSlash)
    counterElement.appendChild(elInnerSecond)
    arrows.appendChild(counterElement)
  }

  const onPrevHandle = () => {
    let index = getLampModalStateIndex - 1

    if (index < 1) {
      return
    }
    const counterEl = document.getElementsByClassName('first_counter')[0]
    counterEl.innerText = `${index + 1} `
  }

  const onNextHandle = () => {
    let index = getLampModalStateIndex + 1
    if (index > lampModalData.length) {
      return
    }
    const counterEl = document.getElementsByClassName('first_counter')[0]
    counterEl.innerText = `${index + 1} `
  }

  return { onPrevHandle, onNextHandle }
}

export default useSplideArrows
