// useMapSelectors.js
import { useSelector } from 'react-redux';
import {
    getCategoriesData,
    getFilteredCategoriesData,
    getIsTimeLineLoading,
    getLampData,
    getLampModalData,
    getLampModalState,
    getMapLoading,
    getMapStylesData,
    getMapZoomData,
    getNextLessonIDData,
    getPrevLessonIDData,
    getScreenShotLoadingST,
    getSelectedArticleData,
    getSelectedLessonId,
    getSelectedMapRequirements,
    getSelectedMapStyle,
    getTimeLineClickState,
    getTimeLineGroupsData,
    getTimeLineItemsData,
    getTimeLineExpendData,
    getTopicId,
    getSelectedMapStyleDark,
    getLegends,
    getFilteredLegendsData,
    getTimeLineZoomST,
    getMiddleTimeLineItemsData,
    getMiddleMaxTimeLineItemsData,
    getLegendsData,
    getTopicsData,
    getIsTopicLoading,
    getLessons,
    getIsLessonLoading,
    getMapSelectedCluster,
    getMapZoomToActionData,
    getMapStateSingleData,
    getMapStylesListData,
    getUser,
    getShowNotificationST,
    getPainterGeoJsonDataST,
    getRulerClickedState,
    getResetCompass,
    getSlidesSelectedSlideData,
    getMapMarkerInfo,
    getNewAngle,
    getTopicNavigationData,
    getLessonNavigationData,
    getLineDrawToolboxState,
    getCommentToolboxState,
    getScreenshotLanguage,
    getMapConfigData,
    getMenuExpendData,
    getBaseMapIsLight,
    getDrawerToolboxClickedState,
    getEraserClickedState,
    getPolygonDrawToolboxState,
    getStickyNotesClickedState,
    getStickyNotesMarkersState,
    getTextToolboxState,
} from "../../../store/selectors";
const useMapSelectors = () => {
    return {
        selectedMapStyleDark: useSelector(getSelectedMapStyleDark),
        selectedMapStyle: useSelector(getSelectedMapStyle),
        baseMapIsLight: useSelector(getBaseMapIsLight),
        mapConfigData: useSelector(getMapConfigData),
        mapStyles: useSelector(getMapStylesData),
        mapStylesList: useSelector(getMapStylesListData),
        categories: useSelector(getCategoriesData),
        filteredCategories: useSelector(getFilteredCategoriesData),
        selectedArticle: useSelector(getSelectedArticleData),
        mapZoom: useSelector(getMapZoomData),
        mapZoomToAction: useSelector(getMapZoomToActionData),
        selectedMapRequirement: useSelector(getSelectedMapRequirements),
        prevLessonID: useSelector(getPrevLessonIDData),
        nextLessonID: useSelector(getNextLessonIDData),
        timeLineItems: useSelector(getTimeLineItemsData),
        middleTimeLineItems: useSelector(getMiddleTimeLineItemsData),
        middleMaxTimeLineItems: useSelector(getMiddleMaxTimeLineItemsData),
        timeLineGroups: useSelector(getTimeLineGroupsData),
        timeLineLoading: useSelector(getIsTimeLineLoading),
        selectedLesson: useSelector(getSelectedLessonId),
        lampData: useSelector(getLampData),
        lampModalState: useSelector(getLampModalState),
        lampModalData: useSelector(getLampModalData),
        timelineExtend: useSelector(getTimeLineExpendData),
        topicId: useSelector(getTopicId),
        mapIsLoading: useSelector(getMapLoading),
        getScreenShotLoadingST: useSelector(getScreenShotLoadingST),
        getTimeLineClickState: useSelector(getTimeLineClickState),
        legends: useSelector(getLegends),
        legendsData: useSelector(getLegendsData),
        filteredLegends: useSelector(getFilteredLegendsData),
        timeLineZoomST: useSelector(getTimeLineZoomST),
        topics: useSelector(getTopicsData),
        topicsIsLoading: useSelector(getIsTopicLoading),
        lessons: useSelector(getLessons),
        lessonsIsLoading: useSelector(getIsLessonLoading),
        mapSelectedCluster: useSelector(getMapSelectedCluster),
        screenShotSingleData: useSelector(getMapStateSingleData),
        user: useSelector(getUser),
        getRulerClickedState: useSelector(getRulerClickedState),
        getCommentToolboxState: useSelector(getCommentToolboxState),
        getStickyNotesClickedState: useSelector(getStickyNotesClickedState),
        getTextToolboxState: useSelector(getTextToolboxState),
        getLineDrawToolboxState: useSelector(getLineDrawToolboxState),
        getPolygonDrawToolboxState: useSelector(getPolygonDrawToolboxState),
        getShowNotificationST: useSelector(getShowNotificationST),
        getPainterGeoJsonDataST: useSelector(getPainterGeoJsonDataST),
        getDrawerToolboxClickedState: useSelector(getDrawerToolboxClickedState),
        getEraserClickedState: useSelector(getEraserClickedState),
        getResetCompass: useSelector(getResetCompass),
        getSlidesSelectedSlideData: useSelector(getSlidesSelectedSlideData),
        getMapMarkerInfo: useSelector(getMapMarkerInfo),
        getNewAngle: useSelector(getNewAngle),
        topicNavigation: useSelector(getTopicNavigationData),
        lessonNavigationData: useSelector(getLessonNavigationData),
        menuExpend: useSelector(getMenuExpendData),
        screenshotLanguage: useSelector(getScreenshotLanguage),
        getStickyNotesMarkersState: useSelector(getStickyNotesMarkersState),
    };
};

export default useMapSelectors;
