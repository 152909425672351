import axios from 'axios'
import mapboxgl from 'mapbox-gl'
import { getData } from './utils'

const sanitizeData = (response) => {
    const resData = response.data.data[0]

    if (!response.data.accepted || !resData) {
        return
    }
    return resData
}
const sanitizeDataBystatus = (response) => {
    if (response.status !== 200) return
    return response.data
}
export const mapPreviewAPIRequest = (body) => {
    return axios
        .get(
            `${process.env.REACT_APP_INDEXING_URL}/api/Map/Preview${
                body && `?${body}`
            }`
        )
        .then(sanitizeData)
}

const mapPreviewTimeAPIRequest = (body) => {
    return axios.get(
        `${process.env.REACT_APP_INDEXING_URL}/api/Map/PreviewMap${
            body && `?${body}`
        }`
    )
}

const mapTimeAPIRequest = (body) => {
    return axios.get(
        `${process.env.REACT_APP_INDEXING_URL}/api/Map${body && `?${body}`}`
    )
}

export const getMapChangeRequest = (body, preview) => {
    if (preview) {
        return mapPreviewTimeAPIRequest(body)
    } else {
        return mapTimeAPIRequest(body)
    }
}

// export const mapLiveAPIRequest = (id, type) => {
//     return axios
//         .get(
//             `${process.env.REACT_APP_INDEXING_URL}/api/Map/${
//                 type.charAt(0).toUpperCase() + type.slice(1)
//             }/${id}`
//         )
//         .then(sanitizeData)
// }
export const mapLiveAPIRequest = (id, type, lng = 1) => {
        return axios
            .get(
                `${process.env.REACT_APP_GIS_URL}/Metadata/${
                    type.charAt(0).toUpperCase() + type.slice(1) + 's'
                }/${id}?languageId=${lng}`
            )
            .then(sanitizeDataBystatus)
            .catch(error => {
                console.log('error', error)
                if (error.response && error.response.status === 404) {
                    console.error('Error 404: Resource not found');
                    // Handle 404 error logic here (e.g., return a default value, show a message)
                    return null; // Or any fallback behavior
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error);
                    throw error; // Rethrow if you don't want to handle it
                }
            });
}

export const mapLessonAPIRequest = (id, lng = 1) => {
    return axios
        .get(`${process.env.REACT_APP_GIS_URL}/Metadata/Lessons/${id}?languageId=${lng}`)
        .then(sanitizeDataBystatus)
}
