import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useLampData = (
  lampData,
  toggleItemsFromMap,
  getLampDataFilteredScreenShot,
  hints,
) => {
  const [lampDataState, setLampDataState] = useState(lampData)
  const [lampEyeIconState, setLampEyeIconState] = useState(false)
  const [hintsFirstLoad, setHintsFirstLoad] = useState(true)

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const isScreenShot = query.get('screenShot')

  const changeLampDataShow = useCallback(
    (id, action) => {
      setLampDataState((prevState) => {
        return prevState.map((item) => {
          if (item.id === id) {
            const subcategory = item.subcategory.find((el) => el.isMain)
            item.isHide = action !== undefined ? action : !item.isHide
            toggleItemsFromMap(
              subcategory.categoryId,
              subcategory.subCategoryId,
              'lamp',
              item.id,
              item.elementIds,
              null,
              !item.isHide,
            )
          }
          return item
        })
      })
    },
    [toggleItemsFromMap],
  )

  const hideLampItems = useCallback(() => {
    Object.values(getLampDataFilteredScreenShot).forEach((value) => {
      changeLampDataShow(value.id, true)
    })
  }, [getLampDataFilteredScreenShot])

  useEffect(() => {
    if (
      hintsFirstLoad &&
      isScreenShot &&
      Object.keys(getLampDataFilteredScreenShot).length > 0
    ) {
      const isHideCount = Object.keys(getLampDataFilteredScreenShot).length
      if (isHideCount === lampData.length) {
        setLampEyeIconState(true)
      } else {
        setLampEyeIconState(false)
      }
      setHintsFirstLoad(false)
    }
  }, [hints, getLampDataFilteredScreenShot])

  useEffect(() => {
    if (
      lampDataState.length > 0 &&
      isScreenShot &&
      Object.keys(getLampDataFilteredScreenShot).length > 0
    ) {
      hideLampItems()
    }
  }, [getLampDataFilteredScreenShot])

  useEffect(() => {
    if (lampData.length > 0) {
      setLampDataState([...lampData])
    }
  }, [lampData])

  return {
    lampDataState,
    lampEyeIconState,
    setLampEyeIconState,
    setLampDataState,
    changeLampDataShow,
  }
}

export default useLampData
