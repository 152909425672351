import React, {useEffect, useState} from 'react';
import './Popup.css';
import UkFlag from "../../../assets/imgs/icons/uk-flag.svg";
import ArmFlag from "../../../assets/imgs/icons/arm-flag.svg";
import {userInfoSettingsURI} from "../../../shared/mockData";
import axios from "axios";
import i18n from "i18next";
import {changeLanguage} from "../../../utils/utils";

const LanguagesPopup = ({ onClose }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const handleSelect = (language) => {
        setSelectedLanguage(language);
    };
    useEffect(() => {
        if (selectedLanguage) {
            const timeout = setTimeout(() => {
                changeLanguage(selectedLanguage)
                onClose();
            }, 1600);
            return () => clearTimeout(timeout);
        }
    }, [selectedLanguage, onClose]);

    return (
        <div className="language-popup-container">
            <div className={`language-popup ${selectedLanguage ? 'popup-animation' : ''}`}  style={{display:'flex',flexDirection:'column'}}>
                <div className="image-options">
                    <div
                        className={`image-circle ${selectedLanguage === 'hy' ? 'selected-language selected-language-to-right' : selectedLanguage ? 'disappear' : ''}`}
                        onClick={() => handleSelect('hy')}
                    >
                        <img src={ArmFlag} alt={"Armenian"}/>
                        <p>Հայերեն</p>
                    </div>
                    <div
                        className={`image-circle ${selectedLanguage === 'en' ? 'selected-language selected-language-to-left' : selectedLanguage ? 'disappear' : ''}`}
                        onClick={() => handleSelect('en')}
                    >
                        <img src={UkFlag} alt={"English"}/>
                        <p>English</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LanguagesPopup;


