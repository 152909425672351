import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {useTranslation} from "react-i18next";
import withRedux from './mapper'

import LampModal from './components/LampModal'
import QuizModal from './components/QuizModal'
import LampTab from './components/LampTab'
import FilterTab from './components/FilterTab'
import CategoryTab from './components/CategoryTab'
import LegendTab from './components/LegendTab'
import MainButton from './components/MainButton'

import useSplideArrows from './hooks/useSplideArrows'
import useOrientationChange from './hooks/useOrientationChange'
import useLampData from './hooks/useLampData'

import Quiz from '../quiz'
import Popup from '../UI/Popup'

import { filterCategory } from './utils/filtring'

import '@splidejs/splide/dist/css/splide.min.css'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import './style.css'

const LeftSide = ({
  menuExpend,
  setSortedTooltipSelectItem,
  filteredCategories,
  toggleItemsFromMap,
  toggleItemsFromTimeLine,
  toggleLegendsFromMapByID,
  lampData,
  quizData,
  hints,
  timelineExtend,
  lampModalState,
  lampModalData,
  setLampModalState,
  selectedQuiz,
  setSelectedQuizData,
  filteredLegends,
  setOpenCategoryID,
  getOpenCategoryID,
  setMapRequiredScreenShot,
  getLampDataFilteredScreenShot,
  setLampSortedTooltipState,
  stopPinAnimation,
  setLampModalStateIndex,
  getLampModalStateIndex,
  filteredCategoriesST,
  toggleItemFromMap,
  globalLoading,
  setLampModalData,
  setFilteredLegends,
}) => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const isScreenShot = query.get('screenShot')
  const isLesson = query.get('lessonID')
  const dispatch = useDispatch()

  const [selectedCategoryId, setSelectedCategoryId] = useState(null)
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState([])
  const [sortBoard, setSortBoard] = useState(false)
  const [filterAction, setFilterAction] = useState('alphabetically')
  const [filteredCategoriesIsMounted, setFilteredCategoriesIsMounted] =
    useState(false)

  const sideBarRef = useRef()
  const categoryModalRefs = useRef([useRef(), useRef(), useRef(), useRef()])

  const isLandscape = useOrientationChange()
  const { onPrevHandle, onNextHandle } = useSplideArrows(
    lampModalState,
    lampModalData,
    getLampModalStateIndex,
  )
  const {
    lampDataState,
    lampEyeIconState,
    setLampEyeIconState,
    setLampDataState,
    changeLampDataShow,
  } = useLampData(
    lampData,
    toggleItemsFromMap,
    getLampDataFilteredScreenShot,
    hints,
  )

  const browserInfo = useMemo(() => {
    const userAgent = navigator.userAgent.toLowerCase()
    return {
      isMozilla: userAgent.includes('firefox'),
      isSafari: /^((?!chrome|android).)*safari/i.test(userAgent),
    }
  }, [])

  useEffect(() => {
    if (!hints) setLampSortedTooltipState(false)
  }, [hints])

  useEffect(() => {
    setSortedTooltipSelectItem(1)
    setFilterAction('alphabetically')
    setSelectedCategoryId(null)
  }, [menuExpend])

  const handleSortedItemChange = (index, close = true) => {
    setFilterAction('alphabetically')
    setSortedTooltipSelectItem(index)
    if (close) setLampSortedTooltipState(false)
  }

  const toggleSubCategory = (id) => {
    const updatedSubCategories = [...selectedSubCategoryId]
    const index = updatedSubCategories.indexOf(id)
    if (index === -1) updatedSubCategories.push(id)
    else updatedSubCategories.splice(index, 1)

    setSelectedSubCategoryId([])
    setTimeout(() => setSelectedSubCategoryId(updatedSubCategories), 1)
  }

  useEffect(() => {
    if (getOpenCategoryID) {
      setSortedTooltipSelectItem(1)
      setFilterAction('alphabetically')
      setSelectedCategoryId(getOpenCategoryID)
      setOpenCategoryID(getOpenCategoryID)
    }
  }, [getOpenCategoryID])

  const handleCategoryToggle = (id) => {
    const isSameCategory = id === selectedCategoryId
    setSortedTooltipSelectItem(1)
    setFilterAction('alphabetically')
    setOpenCategoryID(isSameCategory ? null : id)
    setSelectedCategoryId(isSameCategory ? null : id)
    setMapRequiredScreenShot(null)
  }

  const checkSortOutClick = (e) => {
    if (
      !e.target.closest('.sort-board') &&
      !e.target.closest('.main__subitem-sort')
    ) {
      setSortBoard(false)
      document.removeEventListener('mousemove', checkSortOutClick)
    }
  }

  const toggleSorting = (toggle, action) => {
    if (action) setFilterAction(action)
    setSortBoard(toggle)
  }

  const renderSubItems = useCallback(
    (item) => {
      const data = item.subCategories.reduce(
        (accumulated, { articles, ...subItem }, subIndex) => {
          const filteredArticles = articles?.filter((articleItem) => {
            if (articleItem.isUniq) {
              articleItem.subIndex = subIndex
              articleItem.subItem = subItem
              return true
            }
            return false
          })
          accumulated.push(...filteredArticles)
          return accumulated
        },
        [],
      )
      return filterCategory(filterAction, data)
    },
    [filterAction, filteredCategoriesST],
  )

  const handleCollapsibleToggle = (key, bool) => () => {
    const updatedLegends = [...filteredLegends]
    updatedLegends[0]?.children?.forEach((child) => {
      if (child.key === key) child.closed = !bool
    })
    dispatch(setFilteredLegends(updatedLegends))
  }

  useEffect(() => {
    if (
      filteredCategoriesST?.length &&
      isScreenShot &&
      !filteredCategoriesIsMounted
    ) {
      setTimeout(() => {
        filteredCategoriesST.forEach((item) => {
          if (!item.show) toggleItemFromMap(item.id, 'category', item.show)
          else
            renderSubItems(item).forEach((articleItem) => {
              if (!articleItem.show) {
                toggleItemFromMap(
                  item.id,
                  'article',
                  !articleItem.show,
                  articleItem.subItem?.id,
                  articleItem.id,
                  articleItem.elementsIds,
                  true,
                )
              }
            })
        })
      }, 100)
      setFilteredCategoriesIsMounted(true)
    }
  }, [filteredCategoriesST])

  useEffect(() => {
    if (sortBoard) document.addEventListener('mousemove', checkSortOutClick)
    return () => document.removeEventListener('mousemove', checkSortOutClick)
  }, [sortBoard])

  useEffect(() => {
    if (selectedCategoryId) {
      setSortedTooltipSelectItem(1)
      setFilterAction('alphabetically')
      setSelectedCategoryId(null)
      setOpenCategoryID(null)
    }
  }, [timelineExtend])

  const calculateHeight = (element) => {
    if (!element) return 0
    const windowHeight = (window.innerHeight * 100) / 70
    const { top, height } = element.getBoundingClientRect()
    return Math.max(0, windowHeight - top - height - 135.5)
  }

  const handleCloseLampSlide = () => {
    setLampModalState(false)
    setLampModalStateIndex(null)
    setLampModalData([])
  }

  return (
    <div
      style={{
        width: isLandscape && !menuExpend ? 'calc(36px / 0.7)' : 'unset',
      }}
      className="category_menu_button no-select">
      <div
        ref={sideBarRef}
        className={`main__left left-side ${sideBarRef.current?.clientWidth > 380 ? 'left-to-right' : 'right-to-left'} ${menuExpend && 'first__main_btn'}`}>
        <MainButton globalLoading={globalLoading} />
        <div className={`main__tabs-wrapper ${menuExpend ? 'active' : ''}`}>
          <ul className="main__tabs left-side__tabs">
            {filteredLegends?.map((item, index) => (
              <LegendTab
                key={index}
                item={item}
                selectedCategoryId={selectedCategoryId}
                globalLoading={globalLoading}
                categoryModalRefs={categoryModalRefs.current}
                openCategory={handleCategoryToggle}
                calculateHeight={calculateHeight}
                toggleLegendsFromMapByID={toggleLegendsFromMapByID}
                sortBoard={sortBoard}
                toggleSorting={toggleSorting}
                filterAction={filterAction}
                browserInfo={browserInfo}
                toggleCollapsible={handleCollapsibleToggle}
              />
            ))}
            {filteredCategories?.map((item, index) =>
              !item.isLegend ? (
                <CategoryTab
                  key={index}
                  item={item}
                  index={index}
                  isLesson={isLesson}
                  selectedCategoryId={selectedCategoryId}
                  globalLoading={globalLoading}
                  openCategory={handleCategoryToggle}
                  calculateHeight={calculateHeight}
                  categoryModalRefs={categoryModalRefs.current}
                  browserInfo={browserInfo}
                  toggleItemsFromMap={toggleItemsFromMap}
                  toggleItemsFromTimeLine={toggleItemsFromTimeLine}
                  toggleSorting={toggleSorting}
                  sortBoard={sortBoard}
                  filterAction={filterAction}
                  subItemsRend={renderSubItems}
                  stopPinAnimation={stopPinAnimation}
                />
              ) : null,
            )}
          </ul>
          <div className="left-side__bottom">
            <FilterTab
              setSelectedCategoryId={setSelectedCategoryId}
              setFilterAction={setFilterAction}
              globalLoading={globalLoading}
            />
            {lampData?.length > 0 && (
              <LampTab
                onChangeSortedItem={handleSortedItemChange}
                toggleSorting={toggleSorting}
                globalLoading={globalLoading}
                setSelectedCategoryId={setSelectedCategoryId}
                setFilterAction={setFilterAction}
                filterAction={filterAction}
                browserInfo={browserInfo}
                setLampEyeIconState={setLampEyeIconState}
                setLampDataState={setLampDataState}
                lampEyeIconState={lampEyeIconState}
                sortBoard={sortBoard}
                lampDataState={lampDataState}
                stopPinAnimation={stopPinAnimation}
                changeLampDataShow={changeLampDataShow}
                toggleItemsFromMap={toggleItemsFromMap}
              />
            )}
            {quizData.length > 0 && (
              <QuizModal
                globalLoading={globalLoading}
                setSelectedCategoryId={setSelectedCategoryId}
                setFilterAction={setFilterAction}
              />
            )}
            <Popup
              onClose={() => setSelectedQuizData([])}
              isOpened={selectedQuiz?.length !== 0}>
              <Quiz
                close={() => setSelectedQuizData([])}
                selectedQuiz={selectedQuiz}
              />
            </Popup>
          </div>
        </div>
      </div>
      {lampModalState && (
        <LampModal
          isLandscape={isLandscape}
          globalLoading={globalLoading}
          onCloseLampSlide={handleCloseLampSlide}
          stopPinAnimation={stopPinAnimation}
        />
      )}
    </div>
  )
}

export default withRedux(LeftSide)
